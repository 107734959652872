import * as React from "react";
import { Container, Row} from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../components/css/NewsDetail.scss";
import { Link } from "gatsby";
import { AiFillLinkedin } from 'react-icons/ai';
import { FaTwitter } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';


const EdgeqPressKit5 = () => (
  <Layout>
    <Seo title="EdgeQ | EdgeQ Partners with Mavenir" />


    <section className="News-detail">
        <Container>

                <Row>

                  <div className="detail-head">
                   <Link to="/media/#August" className="back-btn"><FiArrowLeft/> Company Announcements</Link>

                  <h2>EdgeQ Partners with Mavenir on Software Defined 4G and 5G Mobile Networks</h2>
                  </div>

                  <div className="detail-para">
                    
              <p className="text-left"><i>Introducing World’s First Converged Small Cell for Dual Mode 4G and 5G Deployments</i></p>

              <div className="ajit-social">
                <ul className="social-icons">
                  <li>  
                    <a aria-label="Save" href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq" target="_blank" rel="noopener noreferrer"><AiFillLinkedin/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://twitter.com/edgeq_inc?lang=en" target="_blank" rel="noopener noreferrer"><FaTwitter/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" rel="noopener noreferrer"><FaYoutube/></a>
                  </li>
                </ul>
              </div>
            <p><b>SANTA CLARA, CA</b> – September 13, 2022  – <Link to="/">EdgeQ Inc</Link>, , a leader in 5G wireless infrastructure, has partnered with Mavenir, the Network Software Provider building the future of networks, to deliver the world’s first software-defined, dual mode 4G and 5G solution for small cell enterprise markets. This platform unifies the Central Processing Unit (CPU), networking, and a common 4G and 5G Physical Layer in a single solution. Leveraging multi-mode and power over ethernet, Mavenir and EdgeQ deliver a software defined solution that enables seamless transition from 4G to 5G. </p>

            <p>“EdgeQ’s low power, converged 4G and 5G solution addresses an industry pain point. EdgeQ shrink wraps 5G in a manner that allows Mavenir to expedite our time to market with targeted differentiation,” said Pardeep Kohli, Chief Executive Officer of Mavenir. </p>

            <p>By leveraging EdgeQ’s Physical Layer, all running on the RISC-V based architecture, Mavenir is able to deliver a portfolio of 4G and 5G enabled solutions with competitive economics, performance, and form factor. It offers a solution for enterprises looking to deploy private 5G networks for verticals such as manufacturing, construction, energy, automotive, warehousing, surveillance and campuses.</p>

            <p>The highly scalable, flexibly adaptive EdgeQ solution enables a range of functional splits in a completely software-defined manner. By compacting 4G, 5G, and cloud functionalities into a single-chip, EdgeQ provides an all-in-one platform for a diverse portfolio of small cell solutions. The scalable architecture maximizes throughput performance across a large range of concurrent users, all within a compact power envelop. </p>

            <p>“As partners, Mavenir and EdgeQ, leverage best-in-class hardware and software solutions to create a highly flexible, software-defined 4G and 5G platform that is compact and low powered. Together with Mavenir, we are setting an industry precedence that embodies our vision to collapse the entry barriers of 5G in a meaningful manner that accelerates market embrace and deployment,” said Vinay Ravuri, CEO and Founder of EdgeQ.</p>

            <p>The solution is currently under development, with commercial availability planned for 2023. </p>

            <p>To learn more about how EdgeQ is pioneering the 5G infrastructure market, please visit <Link to="/">www.edgeq.io</Link>.</p>
  
              <b>About EdgeQ</b>
              <br />
              <br />
              <p>EdgeQ is a leading innovator in 5G systems-on-a-chip. The company is headquartered in Santa Clara, CA, with offices in San Diego, CA and Bangalore, India. Led by executives from Qualcomm, Intel, and Broadcom, EdgeQ is pioneering converged connectivity and AI that is fully software-customizable and programmable. The company is backed by world-renowned investors. To learn more about EdgeQ, visit <Link to="/">www.edgeq.io</Link></p>
              {/* <b>Contacts</b>
              <br />
              <br />
              <a target="_blank" href="https://goo.gl/maps/qwqsKwcTcEVokx1e6">Jordan Tewell, 10Fold Communications</a>
              <br />
              <a href="mailto:edgeq@10fold.com">edgeq@10fold.com</a>
              <br />
              <a href="tel:+4156666066">(415) 666-6066</a> */}
</div>
                </Row>
        </Container>
    </section>


  </Layout>
);

export default EdgeqPressKit5;